import React from 'react';
import PropTypes from 'prop-types';

import { useDisplayUnit } from '@hooks';
import { Divider, Stack } from '@esgian/esgianui';
import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';

function EquipmentComparisonKpis({ total, topKpis, loading }) {
  const { getKpiDisplayText } = useDisplayUnit();

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={total?.percent}
        value={total?.value ?? null}
        unit={total?.title}
        title={getKpiDisplayText('Total')}
      />
      {!loading ? (
        topKpis?.map((item, i) => (
          <KPIChangeDisplay
            key={i}
            loading={loading}
            percentChange={item?.percent}
            value={item?.value ?? null}
            unit={item?.title}
            title={item.header}
          />
        ))
      ) : (
        <>
          <KPIChangeDisplay loading={loading} />
          <KPIChangeDisplay loading={loading} />
          <KPIChangeDisplay loading={loading} />
          <KPIChangeDisplay loading={loading} />
        </>
      )}
    </Stack>
  );
}

EquipmentComparisonKpis.propTypes = {
  total: PropTypes.object,
  topKpis: PropTypes.array,
  loading: PropTypes.bool
};

EquipmentComparisonKpis.defaultProps = { mainData: {}, baselineData: {}, loading: false };

export default EquipmentComparisonKpis;
