import React, { useMemo } from 'react';
import { Slider } from '@esgian/esgianui';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';

interface TimeRangeSliderProps {
  startDate: string;
  endDate: string;
  selectedRange: [string, string];
  onRangeChange: (range: [string, string]) => void;
}

const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({
  startDate,
  endDate,
  selectedRange,
  onRangeChange
}) => {
  const startMoment = moment(startDate, DATE_FORMAT);
  const endMoment = moment(endDate, DATE_FORMAT);
  const totalDays = endMoment.diff(startMoment, 'days');

  const selectedStart = moment(selectedRange[0], DATE_FORMAT);
  const selectedEnd = moment(selectedRange[1], DATE_FORMAT);

  const range = [selectedStart.diff(startMoment, 'days'), selectedEnd.diff(startMoment, 'days')];

  const handleChange = (_: Event, newValue: number | number[]) => {
    const [startValue, endValue] = newValue as number[];
    const newStart = startMoment.clone().add(startValue, 'days').format(DATE_FORMAT);
    const newEnd = startMoment.clone().add(endValue, 'days').format(DATE_FORMAT);

    onRangeChange([newStart, newEnd]);
  };

  const formatDay = (days: number) => {
    return startMoment.clone().add(days, 'days').format(DATE_FORMAT);
  };

  const markers = useMemo(() => {
    const marks = [];
    const diffInMonths = endMoment.diff(startMoment, 'months', true);

    if (diffInMonths > 2) {
      let current = startMoment.clone().startOf('month');
      while (current.isSameOrBefore(endMoment, 'day')) {
        marks.push({
          value: current.diff(startMoment, 'days'),
          label: current.format('MMM YYYY')
        });
        current = current.add(1, 'month').startOf('month');
      }
    } else {
      for (let i = 0; i <= totalDays; i += 7) {
        marks.push({
          value: i,
          label: startMoment.clone().add(i, 'days').format('MMM D')
        });
      }
    }

    if (!marks.find((mark) => mark.value === totalDays)) {
      marks.push({
        value: totalDays,
        label: endMoment.isSame(moment(), 'day')
          ? 'Today'
          : endMoment.format(diffInMonths > 2 ? 'MMM YYYY' : 'MMM D')
      });
    }

    return marks;
  }, [startMoment, endMoment, totalDays]);

  return (
    <Slider
      value={range}
      onChange={handleChange}
      min={0}
      max={totalDays}
      step={1}
      valueLabelDisplay="auto"
      valueLabelFormat={(value: any) => formatDay(value)}
      marks={markers}
      sx={{
        position: 'absolute',
        bottom: 27,
        zIndex: 2,
        padding: '0',
        m: '0',
        width: '100% !important',
        '& .MuiSlider-markLabel': {
          transform: 'rotate(0deg)',
          whiteSpace: 'nowrap',
          marginLeft: '-20px',
          top: '10px',
          fontSize: 10,
          color: ({ palette }: any) => palette.text.disabled
        },
        '& .MuiSlider-markLabel[data-index="0"]': {
          marginLeft: '1px !important'
        },
        '& .MuiSlider-valueLabelOpen': {
          borderRadius: '4px',
          width: '76px',
          height: '24px',
          rotate: '-45deg',
          transform: 'translate(-6px, 6px)!important'
        },
        '& .MuiSlider-valueLabelCircle': {
          rotate: '-45deg'
        },
        '& .MuiSlider-track': {
          color: '#88C7FF'
        },
        '& .MuiSlider-thumb': {
          color: '#0E9EFF',
          border: 'none'
        },
        '& .MuiSlider-rail': {
          color: '#555555'
        }
      }}
    />
  );
};

export default TimeRangeSlider;
