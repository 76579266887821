import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, EsgianTheme } from '@esgian/esgianui';
import ReactDOMServer from 'react-dom/server';
import { SimpleBarTooltip } from '@components/Tooltips';
import { formatNumberReadable, parseNumberToSignificant } from '@helpers';
import { useTheme } from '@hooks';
import { APP_NAME } from '@constants';

const getYaxis = (vertical, maxValue, secondaryAxis, primaryXAxisLabel) => {
  let yAxis = [
    {
      labels: {
        formatter: (value) => {
          if (typeof value === 'number') {
            if (parseFloat(value) === 0) {
              return 0;
            }
            if (parseInt(value) === 0) {
              return parseFloat(value).toFixed(2);
            }

            return formatNumberReadable(parseInt(value));
          }
          return value.length > 18 ? value.slice(0, 18 - 1) + '...' : value;
        },
        style: {
          fontWeight: 100,
          fontSize: '10px'
        },
        offsetX: 8
      },
      max: (max) => maxValue || max * 1.1,
      tickAmount: 5,
      tickPlacement: 'between',
      decimalsInFloat: primaryXAxisLabel === '%' ? 0 : 0
    }
  ];
  if (secondaryAxis) {
    yAxis.push(secondaryAxis);
  }
  return yAxis;
};

const getVerticalBarOptions = (
  categories,
  theme,
  primaryMaxValue,
  primaryXAxisLabel,
  customTooltipContent,
  primaryTitleOffsetY,
  primaryTitleOffsetX,
  vertical,
  secondaryAxis,
  showLegendSingleSeries,
  legendOffsetY,
  legendOffsetX,
  id,
  splittingLabel
) => {
  const {
    palette: {
      mode,
      tooltip: { background: tooltipBackground, contrastText },
      charts: { background, twoColorsContrast }
    }
  } = theme;
  let offsetX = primaryTitleOffsetX || 0;
  if (primaryTitleOffsetX === null) {
    if (vertical) {
      offsetX = -5;

      if (primaryXAxisLabel.length === 3) {
        offsetX = 10;
      }
      if (primaryXAxisLabel.length === 2) {
        offsetX = 5;
      }
    } else {
      offsetX = 20;
    }
  }
  return {
    chart: {
      animations: {
        enabled: false
      },
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        show: false
      },
      id: `-${id}`
    },
    dataLabels: {
      enabled: false
    },
    colors: twoColorsContrast,
    theme: {
      mode: mode
    },

    xaxis: {
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: !vertical
      },

      tickAmount: vertical ? 5 : undefined,
      categories: categories,
      labels: {
        formatter: (value) => {
          if (typeof value === 'number' && vertical) {
            if (value === 0) {
              return 0;
            }
            return parseNumberToSignificant(value);
          }
          if (splittingLabel) {
            return value.split(' ');
          }
          return value;
        },
        style: {
          rotate: -45,
          fontWeight: 100
        },
        offsetY: vertical ? -5 : 0,
        rotate: -45,
        rotateAlways: true
      }
    },
    title: {
      rotate: -45,
      text: primaryXAxisLabel,
      align: vertical ? 'right' : 'left',
      offsetX: offsetX,
      offsetY: primaryTitleOffsetY,
      floating: true,
      style: {
        rotate: -45,
        fontWeight: 100,
        fontSize: '10px'
      }
    },
    yaxis: getYaxis(vertical, primaryMaxValue, secondaryAxis, primaryXAxisLabel),
    fill: {
      opacity: 1
    },
    grid: {
      padding: { right: vertical ? 35 : 0 },
      xaxis: {
        lines: {
          show: vertical
        }
      },
      yaxis: {
        lines: {
          show: !vertical
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: vertical,
        barHeight: '50%',
        dataLabels: {
          position: 'right'
        }
      }
    },
    stroke: {
      colors: ['transparent'],
      width: 4
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, dataPointIndex }) {
        return ReactDOMServer.renderToString(
          <EsgianTheme app={APP_NAME} mode={mode}>
            <SimpleBarTooltip textColor={contrastText} backgroundColor={tooltipBackground}>
              {customTooltipContent(series, dataPointIndex)}
            </SimpleBarTooltip>
          </EsgianTheme>
        );
      }
    },
    legend: {
      show: true,
      showForSingleSeries: showLegendSingleSeries,
      position: 'top',
      markers: { radius: 0, width: 8, height: 8 },
      offsetY: legendOffsetY,
      offsetX: legendOffsetX,
      horizontalAlign: 'left'
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};

function SimpleBarChart({
  series,
  categories,
  primaryMaxValue,
  primaryXAxisLabel,
  customTooltipContent,
  primaryTitleOffsetY,
  primaryTitleOffsetX,
  secondaryAxis,
  height,
  loading,
  vertical,
  showLegendSingleSeries,
  legendOffsetY,
  legendOffsetX,
  id
}) {
  const { theme } = useTheme();
  const options = useMemo(() => {
    return getVerticalBarOptions(
      categories,
      theme,
      primaryMaxValue,
      primaryXAxisLabel,
      customTooltipContent,
      primaryTitleOffsetY,
      primaryTitleOffsetX,
      vertical,
      secondaryAxis,
      showLegendSingleSeries,
      legendOffsetY,
      legendOffsetX,
      id
    );
  }, [theme, categories, primaryXAxisLabel, primaryTitleOffsetY]);

  return (
    <ApexChart
      newLoadingAnimation
      loading={loading}
      height={height}
      options={options || {}}
      type={'bar'}
      data={series}
    />
  );
}

SimpleBarChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number])),
  categories: PropTypes.arrayOf(PropTypes.string),
  primaryMaxValue: PropTypes.number,
  height: PropTypes.number,
  primaryTitleOffsetY: PropTypes.number,
  legendOffsetX: PropTypes.number,
  legendOffsetY: PropTypes.number,
  primaryTitleOffsetX: PropTypes.number,
  primaryXAxisLabel: PropTypes.string,
  secondaryAxis: PropTypes.object,
  vertical: PropTypes.bool,
  showLegendSingleSeries: PropTypes.bool,
  loading: PropTypes.bool,
  customTooltipContent: PropTypes.func.isRequired,
  id: PropTypes.string,
  splittingLabel: PropTypes.bool
};

SimpleBarChart.defaultProps = {
  primaryMaxValue: undefined,
  primaryXAxisLabel: 'MWh',
  primaryTitleOffsetY: 300,
  legendOffsetX: -20,
  legendOffsetY: -20,
  primaryTitleOffsetX: null,
  height: 380,
  vertical: false,
  showLegendSingleSeries: false,
  loading: false,
  secondaryAxis: null,
  series: [],
  categories: [],
  id: '',
  splittingLabel: true
};

export default SimpleBarChart;
