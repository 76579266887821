/* eslint-disable no-prototype-builtins */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@esgian/esgianui';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { PeriodicOverviewWithDetailsChart } from '@components/Charts';
import { useDisplayUnit, useSeriesBuilder, useTheme } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMainPageSlice,
  setChartSelectedDate
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getDisplayUnit } from '@store/features';
import { getStackedBarChartMax, getUnixTimeStamp, getValue } from '@helpers';
import moment from 'moment-timezone';
import { API_ROLLUP, DATE_FORMAT, EQUIPMENT_TYPES, TIMESTAMP, UTC_TIMESTAMP } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { useTimezone } from '@hooks/useTimezone';
import { fetchEquipmentData } from '@api/Rig';
import { DailyRegeneratedEnergyDrawworks } from '@components/SubSections/Equipment';

function PeriodicOverviewDetails({ equipmentData, loading }) {
  const dispatch = useDispatch();
  const [selectedView, handleViewChange] = useState('hour');
  const [logInfo, setLogInfo] = useState(null);
  const { getCanvasTimeSeriesData } = useSeriesBuilder();
  const { getUnitTitle } = useDisplayUnit();
  const { theme } = useTheme();
  const {
    palette: { charts }
  } = theme;
  const displayUnit = useSelector(getDisplayUnit);
  const { selectedRig, chartSelectedDate } = useSelector(getMainPageSlice);
  const { selectedTimeZone, timezoneOffsetString } = useTimezone();

  const { primaryUnit, primarySeries, firstChartDate } = useMemo(() => {
    if (!equipmentData) return { primarySeries: [], firstChartDate: null };

    const overviewSeries = [];
    const { clarifyData } = equipmentData;

    EQUIPMENT_TYPES.forEach(({ color, order, group, name, key }) => {
      let objectKey = `${key}${displayUnit}`;
      if (!clarifyData.hasOwnProperty(objectKey)) return;

      let data = clarifyData[objectKey];
      const { defaultArray, convertedArray } = getCanvasTimeSeriesData(data, displayUnit);

      overviewSeries.push({
        label: name,
        type: 'bar',
        order: order,
        group: group,
        borderColor: charts[color.name][color.index],
        backgroundColor: charts[color.name][color.index],
        borderSkipped: true,
        stack: 1,
        defaultArray: defaultArray,
        data: [],
        convertedArray: convertedArray
      });
    });

    let maxTot = getStackedBarChartMax(
      overviewSeries?.map(({ defaultArray }) => {
        return { data: defaultArray };
      })
    );

    const { title, converted } = getUnitTitle(maxTot);

    return {
      primaryUnit: title,
      primarySeries: overviewSeries.map((series) => {
        series.data = converted ? series.convertedArray : series.defaultArray;
        series.unit = title;
        series.converted = converted;
        return series;
      }),
      firstChartDate: overviewSeries[0]?.defaultArray[0]?.x || null
    };
  }, [displayUnit, equipmentData]);

  // Use an effect to dispatch setChartSelectedDate
  useEffect(() => {
    if (!chartSelectedDate && firstChartDate) {
      dispatch(
        setChartSelectedDate({
          date: moment.utc(firstChartDate).format(UTC_TIMESTAMP),
          index: 0,
          isLast: false
        })
      );
    }
  }, [chartSelectedDate, firstChartDate, dispatch]);

  const secondaryDataQuery = useQuery({
    queryKey: [
      'equipment',
      {
        selectedView,
        chartSelectedDate,
        selectedRig
      }
    ],
    enabled: !!selectedRig && !!selectedView,
    placeholderData: [],
    queryFn: ({ signal }) => {
      const rollup = selectedView === 'hour' ? API_ROLLUP.HOUR : API_ROLLUP.MIN;

      let sDate = moment(chartSelectedDate.date).startOf('day');
      let eDate = moment(chartSelectedDate.date).endOf('day');

      if (chartSelectedDate.index >= 1 && selectedView === 'hour') {
        sDate = sDate.subtract(1, 'days');
      }
      if (!chartSelectedDate.isLast && selectedView === 'hour') {
        eDate = eDate.add(1, 'days');
      }

      const timezoneMoment = moment.tz(eDate, selectedTimeZone); // Ensure timezone alignment
      const now = moment.tz(selectedTimeZone); // Current time in the same timezone

      if (timezoneMoment.isAfter(now)) {
        eDate = now; // Adjust `eDate` to current time if it's in the future
      }
      const startDate = `${sDate.format(TIMESTAMP)}${timezoneOffsetString}`;
      const endDate = `${eDate.format(TIMESTAMP)}${timezoneOffsetString}`;

      return fetchEquipmentData(signal, { selectedRig, startDate, endDate }, rollup)
        .then((result) => {
          let res = { ...result };
          res.clarifyData.DWEnergy = { ...(result.clarifyData?.DWEnergyCons ?? {}) };
          let { clarifyData } = res;
          const tempSeries = [];
          EQUIPMENT_TYPES.forEach(({ color, order, group, name, key }) => {
            let data =
              clarifyData[`${key}TotP`] ?? clarifyData[`${key}totP`] ?? clarifyData[`${key}RLtotP`];
            if (!data) return;
            let seriesData = data
              ? Object.keys(data).map((date) => {
                  return { x: getUnixTimeStamp(date), y: getValue(data[date], false) };
                })
              : [];

            tempSeries.push({
              label: name,
              type: 'line',
              order: order,
              group: group,
              borderColor: charts[color.name][color.index],
              data: seriesData
            });
          });

          return tempSeries;
        })
        .catch(() => {
          return [];
        });
    }
  });

  const { sDate, eDate } = useMemo(() => {
    if (!chartSelectedDate?.date) return { sDate: null, eDate: null };
    let sDate = moment(chartSelectedDate?.date).startOf('day');
    let eDate = moment.tz(chartSelectedDate?.date, selectedTimeZone).endOf('day');

    if (chartSelectedDate.index >= 1) {
      sDate = sDate.subtract(1, 'days');
    }
    if (!chartSelectedDate.isLast) {
      eDate = eDate.add(1, 'days');
    }
    if (eDate.isAfter(moment.tz(selectedTimeZone.date))) {
      eDate = moment.tz(selectedTimeZone.date);
    }
    sDate = sDate.format(DATE_FORMAT);
    eDate = eDate.format(DATE_FORMAT);
    return { sDate, eDate };
  }, [chartSelectedDate]);

  return (
    <Paper sx={{ p: 2 }}>
      <PeriodicOverviewWithDetailsChart
        secondaryPrimaryProps={{
          setSelectedColumn: (v) => dispatch(setChartSelectedDate(v)),
          loading: loading,
          equipmentData: equipmentData
        }}
        SecondaryPrimaryChart={DailyRegeneratedEnergyDrawworks}
        selectedColumn={{ ...chartSelectedDate, selectedDate: { sDate, eDate } } ?? {}}
        setSelectedColumn={(v) => dispatch(setChartSelectedDate(v))}
        secondarySeries={secondaryDataQuery.data}
        secondaryUnit={'kW'}
        handleLogClick={setLogInfo}
        detailsTitle={'Equipment power details'}
        detailsTitleTooltip={`Total power consumption per hour for each equipment. Based on the day selected in the graph above, it shows the equipment power consumption for the day before, the selected day and the day after. Choose “View by Min” to view the power consumption in more detail`}
        handleViewChange={handleViewChange}
        primaryUnit={primaryUnit}
        primarySeries={primarySeries}
        loading={loading}
        selectedView={selectedView}
        subTitle="Detailed equipment power consumption in selection"
        title="Equipment overview"
        titleTooltipText={`Total consumption or emissions per day for each equipment in the selected time period.
Clicking on one of the bars will reveal the details per hour for the day before, selected day and the next day in the Equipment details graph below.`}
        id={'equipment-power-detailed-chart'}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Paper>
  );
}

PeriodicOverviewDetails.propTypes = {
  loading: PropTypes.bool,
  equipmentData: PropTypes.object,
  primarySeries: PropTypes.arrayOf(PropTypes.object)
};

PeriodicOverviewDetails.defaultProps = {
  loading: false,
  equipmentData: null
};

export default PeriodicOverviewDetails;
