import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowDownwardRoundedIcon,
  ArrowUpwardRoundedIcon,
  Button,
  Paper,
  Stack,
  Table,
  Typography,
  FilterSlider
} from '@esgian/esgianui';
import { APP_NAME, DISPLAY_UNIT, WARNINGS } from '@constants';
import moment from 'moment-timezone';
import { useDisplayUnit, useTheme } from '@hooks';
import WarningDetailsView from '@components/Tables/FocusAreasTable/WarningDetailsView';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { getDollarAmountReadable } from '@helpers';
import DateRangePicker from '@components/Inputs/DateRangePicker';
import { useSelector } from 'react-redux';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { getSideMenuOpen } from '@store/features';
import { useTimezone } from '@hooks/useTimezone';

function FocusAreasTable({ analyticsData, loading }) {
  const [sortOrder, setSortOder] = useState({ name: 'endDate', direction: 'desc' });
  const [expandedRow, setExpandedRow] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [logInfo, setLogInfo] = useState(null);
  const { valueWithUnit } = useDisplayUnit();
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const [reloadKey, setReloadKey] = useState(0);
  const { selectedTimeZone } = useTimezone();

  const {
    theme: {
      palette: { mode }
    }
  } = useTheme();

  useEffect(() => {
    setReloadKey((prev) => prev + 1);
  }, [sideMenuOpen]);

  const { minStartDate, maxEndDate } = useMemo(() => {
    let minStart, maxEnd;
    if (analyticsData?.length) {
      minStart = analyticsData.reduce((min, item) => {
        return moment(item.startDate).isBefore(moment(min)) ? item.startDate : min;
      }, analyticsData[0].startDate);

      maxEnd = analyticsData.reduce((max, item) => {
        return moment(item.endDate).isAfter(moment(max)) ? item.endDate : max;
      }, analyticsData[0].endDate);

      // Check if time part of maxEnd is greater than 00:00:00
      const maxEndMoment = moment(maxEnd);
      if (maxEndMoment.format('HH:mm:ss') !== '00:00:00') {
        maxEnd = maxEndMoment.add(1, 'day').startOf('day').format(); // Add one day and set to 00:00:00
      }
    }

    return { minStartDate: minStart, maxEndDate: maxEnd };
  }, [analyticsData]);

  const minFuelSavings = 0;
  const maxFuelSavings = useMemo(() => {
    const max =
      analyticsData?.reduce((max, row) => Math.max(max, parseFloat(row?.fuelSaving)), 0) * 1000;

    return Math.ceil(max / 10 + 1) * 10;
  }, [analyticsData]);

  const minDurationHours = 0;
  const maxDurationHours = useMemo(() => {
    const maxDurationSecs =
      analyticsData?.reduce((max, row) => {
        const start = moment.utc(row?.startDate);
        const end = moment.utc(row?.endDate);
        const durationSecs = end.diff(start, 'seconds');
        return Math.max(max, durationSecs);
      }, 0) / 3600 ?? 0;
    return Math.ceil(maxDurationSecs / 10 + 1) * 10;
  }, [analyticsData]);

  const minCostSaving = 0;
  const maxCostSaving = useMemo(() => {
    const max = analyticsData?.map && Math.max(...analyticsData.map((row) => row?.costSaving || 0));
    const maxValue = Math.max(max);

    return Math.ceil(maxValue / 10 + 1) * 10;
  }, [analyticsData]);

  const columns = useMemo(() => {
    return [
      {
        name: 'type',
        label: 'Detected Focus areas',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filter: true,
          filterType: 'multiselect',
          customBodyRender: (value) => {
            return WARNINGS[value];
          }
        }
      },
      {
        name: 'startDate',
        label: '',
        options: {
          sort: false,
          display: false,
          filter: false,
          download: false,
          sortThirdClickReset: true
        }
      },
      {
        name: 'endDate',
        label: 'Period',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: (value) => {
              return (
                <div style={{ textAlign: 'center', fontSize: '10px' }}>
                  <div>PERIOD</div>
                  <div>
                    {value?.[0]} / {value?.[1]}
                  </div>
                </div>
              );
            }
          },
          sortCompare: (order) => {
            return (obj1, obj2) => {
              const { rowData: firstRow } = obj1;
              const { rowData: secRow } = obj2;
              const val1 = moment(firstRow[2]).unix();
              const val2 = moment(secRow[2]).unix();
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const filterValues = filterList[index] || {};
              const handleDateChange = (selectedDate) => {
                onChange([selectedDate?.startDate, selectedDate?.endDate], index, column);
              };

              return (
                <DateRangePicker
                  inputVariant={'standard'}
                  inputSize={'small'}
                  fullWidth
                  minStartDate={minStartDate}
                  maxDate={maxEndDate}
                  startDate={filterValues?.[0]}
                  endDate={filterValues?.[1]}
                  onSave={handleDateChange}
                />
              );
            },
            logic: (dateStr, filters) => {
              const startDateFilter = filters[0]
                ? moment.tz(filters[0], selectedTimeZone).startOf('day')
                : null;
              const endDateFilter = filters[1]
                ? moment.tz(filters[1], selectedTimeZone).endOf('day')
                : null;

              const rowDate = moment(dateStr);

              if (startDateFilter && endDateFilter) {
                return !rowDate.isBetween(startDateFilter, endDateFilter, null, '[]');
              }

              if (startDateFilter) {
                return !rowDate.isSameOrAfter(startDateFilter);
              }

              if (endDateFilter) {
                return !rowDate.isSameOrBefore(endDateFilter);
              }

              return false;
            }
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            let start = moment.parseZone(rowData[1]);
            let end = moment.parseZone(rowData[2]);
            return (
              <Stack direction={'row'} spacing={2}>
                <Stack alignItems={'flex-end'}>
                  <Typography variant={'caption'}>{start.format('YYYY-MM-DD')}</Typography>
                  <Typography variant={'caption'}>{start.format('HH:mm:ss')}</Typography>
                </Stack>
                <Stack>
                  <Typography variant={'caption'}>-</Typography>
                </Stack>
                <Stack alignItems={'flex-end'}>
                  <Typography variant={'caption'}>{end.format('YYYY-MM-DD')}</Typography>
                  <Typography variant={'caption'}>{end.format('HH:mm:ss')}</Typography>
                </Stack>
              </Stack>
            );
          }
        }
      },
      {
        name: '',
        label: 'Duration',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: (value) => {
              return (
                <div style={{ textAlign: 'center', fontSize: '10px' }}>
                  <div>DURATION</div>
                  <div>
                    Min: {parseFloat(value?.[0])} h / Max: {parseFloat(value?.[1])} h
                  </div>
                </div>
              );
            }
          },
          filterOptions: {
            logic: (durationString, filters) => {
              if (!filters?.length || !durationString) return false;

              const days = parseInt(durationString.match(/(\d+)\s*d/)?.[1] || 0, 10);
              const hours = parseInt(durationString.match(/(\d+)\s*h/)?.[1] || 0, 10);
              const minutes = parseInt(durationString.match(/(\d+)\s*m/)?.[1] || 0, 10);

              const durationInHours = (days * 24 * 60 + hours * 60 + minutes) / 60;
              const [minFilterHours, maxFilterHours] = filters.map(Number);

              return durationInHours < minFilterHours || durationInHours > maxFilterHours;
            },
            display: (filterList, onChange, index, column) => {
              const currentRange =
                filterList[index] && filterList[index].length
                  ? filterList[index]
                  : [minDurationHours, maxDurationHours];
              const handleChange = (newValue) => {
                const adjustedValues = newValue?.map((val) => val);
                onChange(adjustedValues, index, column);
              };

              return (
                <FilterSlider
                  disableMarkRotation
                  sliderWidth="92%"
                  title="Duration"
                  value={currentRange}
                  min={minDurationHours}
                  max={maxDurationHours}
                  step={1}
                  marks={[
                    { label: `${minDurationHours} h`, value: minDurationHours },
                    { label: `${maxDurationHours} h`, value: maxDurationHours }
                  ]}
                  onChange={handleChange}
                />
              );
            }
          },

          sortCompare: (order) => {
            return (obj1, obj2) => {
              const { rowData: firstRow } = obj1;
              const { rowData: secRow } = obj2;
              const val1 = moment(firstRow[1]).diff(moment(firstRow[2]), 'seconds');
              const val2 = moment(secRow[1]).diff(moment(secRow[2]), 'seconds');
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            let start = moment(rowData[1]);
            let end = moment(rowData[2]);
            const duration = moment.duration(end.diff(start));
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();

            let formattedResult = ``;
            if (days) {
              formattedResult = `${days}d ${hours}h ${minutes}m`;
            }
            if (!days && hours) {
              formattedResult = `${hours}h ${minutes}m`;
            }
            if (!days && !hours) {
              formattedResult = `${minutes}m`;
            }
            return formattedResult;
          }
        }
      },
      {
        name: 'fuelSaving',
        label: 'Potential fuel savings',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: (value) => {
              return (
                <div style={{ textAlign: 'center', fontSize: '10px' }}>
                  <div>FUEL SAVINGS</div>
                  <div>
                    Min: {parseFloat(value?.[0]) * 1000} kg / Max: {parseFloat(value?.[1]) * 1000}{' '}
                    kg
                  </div>
                </div>
              );
            }
          },
          filterOptions: {
            logic: (fuelSaving, filters) => {
              const weightValueStr = fuelSaving?.replace(/[^0-9.]/g, '');
              let value = parseFloat(weightValueStr) / 1000;
              if (fuelSaving.includes('mt')) {
                value *= 1000;
              }

              const min = parseFloat(filters[0]);
              const max = parseFloat(filters[1]);
              return value < min || value > max;
            },
            display: (filterList, onChange, index, column) => {
              const currentRange = filterList[index]?.length
                ? filterList[index].map((val) => val * 1000)
                : [minFuelSavings, maxFuelSavings];
              return (
                <FilterSlider
                  sliderWidth="92%"
                  title="Fuel savings"
                  value={currentRange}
                  min={minFuelSavings}
                  max={maxFuelSavings}
                  step={0.05}
                  onChange={(newValue) => {
                    const adjustedValues = newValue?.map((val) => val / 1000);
                    onChange(adjustedValues, index, column);
                  }}
                  marks={[
                    { label: `${minFuelSavings.toFixed(1)} kg`, value: minFuelSavings },
                    { label: `${maxFuelSavings.toFixed(1)} kg`, value: maxFuelSavings }
                  ]}
                  disableMarkRotation
                />
              );
            }
          },
          customBodyRender: (value) => {
            return valueWithUnit(value, DISPLAY_UNIT.FUEL);
          },
          sortCompare: (order) => {
            return (obj1, obj2) => {
              return (obj1.data - obj2.data) * (order === 'asc' ? 1 : -1);
            };
          }
        }
      },
      {
        name: 'costSaving',
        label: 'Potential cost savings',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: (value) => {
              return (
                <div style={{ textAlign: 'center', fontSize: '10px' }}>
                  <div>COST SAVINGS</div>
                  <div>
                    Min: {parseFloat(value?.[0])} USD / Max: {parseFloat(value?.[1])} USD
                  </div>
                </div>
              );
            }
          },
          filterOptions: {
            logic: (valueString, filters) => {
              let value = parseFloat(valueString);
              if (valueString.toLowerCase().includes('k')) {
                value *= 1000;
              }
              const [minRange, maxRange] = filters.map(Number);
              return value < minRange || value > maxRange;
            },
            display: (filterList, onChange, index, column) => {
              const currentRange = filterList[index]?.length
                ? filterList[index].map((val) => val)
                : [minCostSaving, maxCostSaving];
              return (
                <FilterSlider
                  sliderWidth="92%"
                  title="Cost savings"
                  value={currentRange}
                  min={minCostSaving}
                  max={maxCostSaving}
                  step={5}
                  onChange={(newValue) => {
                    const adjustedValues = newValue?.map((val) => val);
                    onChange(adjustedValues, index, column);
                  }}
                  marks={[
                    { label: `${minCostSaving.toFixed(1)} USD`, value: minCostSaving },
                    { label: `${maxCostSaving.toFixed(1)} USD`, value: maxCostSaving }
                  ]}
                  disableMarkRotation
                />
              );
            }
          },
          customBodyRender: (value) => {
            return `${getDollarAmountReadable(value)} USD`;
          }
        }
      },
      {
        name: '',
        label: 'Logs',
        options: {
          sort: false,
          filter: false,
          download: false,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return (
              <Button
                size={'small'}
                variant={'text'}
                onClick={() => {
                  setLogInfo({ startDate: rowData[1], endDate: rowData[2], unit: 'custom' });
                }}>
                <Typography variant={'caption'}>Open logs</Typography>
              </Button>
            );
          }
        }
      }
    ];
  }, [analyticsData]);

  let finalColumn = useMemo(() => {
    return [
      {
        name: '',
        label: 'Details',
        options: {
          sort: false,
          filter: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const { rowData } = tableMeta;
            let start = rowData[1];
            let end = rowData[2];

            let index = analyticsData?.findIndex(
              ({ startDate, endDate }) => startDate === start && endDate === end
            );
            return (
              <Button
                onClick={() => {
                  if (expandedRow[0] === index) {
                    setExpandedRow([]);
                    setExpandedRowData(null);
                  } else {
                    setExpandedRow([index]);
                    setExpandedRowData(analyticsData[index]);
                  }
                }}
                endIcon={
                  expandedRow[0] === index ? (
                    <ArrowUpwardRoundedIcon />
                  ) : (
                    <ArrowDownwardRoundedIcon />
                  )
                }
                size={'small'}
                variant={'text'}>
                <Typography variant={'caption'}>View</Typography>
              </Button>
            );
          }
        }
      }
    ];
  }, [expandedRow, analyticsData]);

  const options = useMemo(() => {
    return {
      onDownload: (buildHead, buildBody, columns, data) => {
        let downloadColumns = [...columns];

        // Split up the period column
        downloadColumns[1].download = true;
        downloadColumns[1].label = 'Period start';
        downloadColumns[2].label = 'Period end';

        // Handle utf for norwegian letters
        return '\uFEFF' + buildHead(downloadColumns) + buildBody(data);
      },
      selectableRows: 'none',
      responsive: 'standard',
      filter: true,
      search: true,
      download: true,
      downloadOptions: {
        filename: 'focus-areas'
      },
      setTableProps: () => {
        return {
          id: 'focus-areas-table'
        };
      },
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      print: false,
      viewColumns: false,
      pagination: true,
      elevation: 0,
      toolbar: true,
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, []);

  return (
    <Paper
      id={'focus-area-list'}
      sx={{ maxWidth: sideMenuOpen ? 'calc(100vw - 316px)' : 'calc(100vw - 100px)' }}>
      <Table
        customStyle={{}}
        key={reloadKey}
        title={
          <TextWithTooltipIcon
            tooltipText={'List of all focus areas that has occurred in the selected time period.'}
            label={<Typography variant={'h6'}>Focus areas list</Typography>}
          />
        }
        mode={mode}
        data={analyticsData}
        columns={[...columns, ...finalColumn]}
        options={{
          ...options,
          sortOrder: {
            name: sortOrder.name,
            direction: sortOrder.direction
          },
          rowsExpanded: expandedRow,
          renderExpandableRow: (rowData) => {
            const numColumns = rowData.length + 1;
            return <WarningDetailsView numColumns={numColumns} rowData={expandedRowData} />;
          },
          onColumnSortChange: (changedColumn, direction) => {
            setSortOder({ name: changedColumn, direction });
          }
        }}
        app={APP_NAME}
        loading={loading}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Paper>
  );
}

FocusAreasTable.propTypes = {
  analyticsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

FocusAreasTable.defaultProps = { analyticsData: [], loading: false };

export default FocusAreasTable;
