import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import {
  fetchAnalyticsData,
  fetchDrillingActivitiesV2Data,
  fetchEquipmentData,
  fetchOverviewData,
  fetchOverviewKeyFiguresData,
  fetchPowerMgmtData
} from '@api/Rig';
import { API_ROLLUP, SUB_SECTIONS } from '@constants';
import { useTimezone } from '@hooks/useTimezone';
import { useCallback } from 'react';

export const useMainPageQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance
  const {
    selectedRig,
    startDate: start,
    endDate: end,
    selectedSection
  } = useSelector(getMainPageSlice);
  const { calculateDates } = useTimezone();
  const { dateStart: startDate, dateEnd: endDate } = calculateDates(start, end);
  const { OVERVIEW, ANALYTICS, DRILLING_ACTIVITIES, EQUIPMENT, POWER_MGMT } = SUB_SECTIONS;

  const rigOverviewQuery = useQuery({
    queryKey: ['overview', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled:
      !!selectedRig && !!startDate && !!endDate && [OVERVIEW, ANALYTICS].includes(selectedSection),
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      return fetchOverviewData(signal, queryKey[1], API_ROLLUP.DAY).then((resp) => {
        let res = { ...resp };
        resp.clarifyData.DWEnergy = { ...(resp.clarifyData?.DWEnergyCons ?? {}) };
        return res;
      });
    }
  });

  const rigKeyFiguresQuery = useQuery({
    queryKey: ['keyFigures', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled: !!selectedRig && !!startDate && !!endDate && selectedSection === OVERVIEW,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      return fetchOverviewKeyFiguresData(signal, queryKey[1], API_ROLLUP.HOUR);
    }
  });

  const rigAnalyticsQuery = useQuery({
    queryKey: ['analytics', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled:
      !!selectedRig && !!startDate && !!endDate && [OVERVIEW, ANALYTICS].includes(selectedSection),
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      return fetchAnalyticsData(signal, queryKey[1], API_ROLLUP.MIN);
    }
  });
  const rigDrillingActivityQuery = useQuery({
    queryKey: [
      'drillingActivity',
      { selectedRig: selectedRig, startDate: startDate, endDate: endDate }
    ],
    enabled: !!selectedRig && !!startDate && !!endDate && selectedSection === DRILLING_ACTIVITIES,
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      return fetchDrillingActivitiesV2Data(signal, queryKey[1], API_ROLLUP.HOUR);
    }
  });

  const rigEquipmentQuery = useQuery({
    queryKey: ['equipment', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled: !!selectedRig && !!startDate && !!endDate && [EQUIPMENT].includes(selectedSection),
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      return fetchEquipmentData(signal, queryKey[1], API_ROLLUP.DAY).then((resp) => {
        let res = { ...resp };
        resp.clarifyData.DWEnergy = { ...(resp.clarifyData?.DWEnergyCons ?? {}) };
        return res;
      });
    }
  });
  const rigPowerMgmtQuery = useQuery({
    queryKey: ['powerMgmt', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled: !!selectedRig && !!startDate && !!endDate && selectedSection === POWER_MGMT,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      return fetchPowerMgmtData(signal, queryKey[1], API_ROLLUP.DAY);
    }
  });

  const resetMainPageData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['overview'] });
    await queryClient.invalidateQueries({ queryKey: ['analytics'] });
    await queryClient.invalidateQueries({ queryKey: ['powerMgmt'] });
    await queryClient.invalidateQueries({ queryKey: ['equipment'] });
    await queryClient.invalidateQueries({ queryKey: ['drillingActivities'] });
    await queryClient.invalidateQueries({ queryKey: ['keyFigures'] });
  }, [queryClient]);

  const resetAnalyticsData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['analytics'] });
  }, [queryClient]);

  return {
    rigOverviewQuery,
    rigPowerMgmtQuery,
    rigKeyFiguresQuery,
    rigEquipmentQuery,
    rigDrillingActivityQuery,
    rigAnalyticsQuery,
    resetMainPageData,
    resetAnalyticsData
  };
};
