import React, { useEffect, useMemo, useState } from 'react';
import {
  getMainPageSlice,
  setMainPagePeriod
} from '../../store/features/filters/MainPageSlice/MainPageSlice';
import { Box, ChevronRightIcon, IconButton } from '@esgian/esgianui';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { useTimezone } from '../../hooks/useTimezone';
import { useQuery } from '@tanstack/react-query';
import { fetchRigWells } from '../../api/DdrReport';
import TimeRangeSlider from './TimeRangeSlider';
import WellsButton from './WellsButton';
import BarChart from './BarChart';
import DateRangeDropdown from '../DateRangeDropdown';

export const DateSlider = () => {
  const dispatch = useDispatch();
  const {
    selectedRig,
    startDate: selectedStartDate,
    endDate: selectedEndDate
  } = useSelector(getMainPageSlice);
  const { selectedTimeZone } = useTimezone();
  const { selectedDateName } = useSelector(getMainPageSlice);

  const [currentSelectedDates, setCurrentSelectedDates] = useState<[string, string]>([
    selectedStartDate,
    selectedEndDate
  ]);

  useEffect(() => {
    setCurrentSelectedDates([selectedStartDate, selectedEndDate]);
  }, [selectedStartDate, selectedEndDate]);

  const fullStartDate = useMemo(() => {
    return DateTime.fromISO(selectedRig.lowerLimit);
  }, [selectedTimeZone, selectedRig]);

  const fullEndDate = useMemo(() => {
    return DateTime.fromISO(DateTime.now().toString());
  }, [selectedTimeZone, selectedRig]);

  const isMoreThanOneYear = useMemo(
    () => fullEndDate.diff(fullStartDate, 'months').months > 12,
    [fullStartDate, fullEndDate]
  );

  const initialDisplayedStart = useMemo(() => {
    return isMoreThanOneYear ? fullEndDate.minus({ months: 12 }) : fullStartDate;
  }, [isMoreThanOneYear]);

  const initialDisplayedEnd = useMemo(() => fullEndDate, [fullEndDate]);

  const [currentDisplayedDates, setCurrentDisplayedDates] = useState([
    initialDisplayedStart,
    initialDisplayedEnd
  ]);

  const handleMoveTimeline = (direction: string) => {
    const [currentStart, currentEnd] = currentDisplayedDates;
    const newStart =
      direction === 'left' ? currentStart.minus({ days: 30 }) : currentStart.plus({ days: 30 });

    const newEnd =
      direction === 'left' ? currentEnd.minus({ days: 30 }) : currentEnd.plus({ days: 30 });

    if (newStart < fullStartDate) {
      setCurrentDisplayedDates([fullStartDate, currentDisplayedDates[1]]);
    } else if (newEnd > fullEndDate) {
      setCurrentDisplayedDates([currentDisplayedDates[0], fullEndDate]);
    } else {
      setCurrentDisplayedDates([newStart, newEnd]);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const [startDate, endDate] = currentSelectedDates;
      const isStartDateChanged =
        DateTime.fromISO(startDate).toISODate() != DateTime.fromISO(selectedStartDate).toISODate();
      const isEndDateChanged =
        DateTime.fromISO(endDate).toISODate() != DateTime.fromISO(selectedEndDate).toISODate();
      if (isStartDateChanged || isEndDateChanged) {
        onSave({ startDate, endDate });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSelectedDates]);

  const onSave = (period: { startDate: string; endDate: string }, type?: string, name?: string) => {
    dispatch(setMainPagePeriod({ ...period, type, name }));
  };

  const wellsLookup = useQuery({
    queryKey: ['lookupWells', { selectedRig: selectedRig }],
    enabled: !!selectedRig,
    placeholderData: [],
    queryFn: ({ queryKey, signal }: any) => {
      return fetchRigWells(signal, { ...queryKey[1] })
        .then((result: any) => {
          return result.filter(({ starttime, endtime }: { starttime: string; endtime: string }) => {
            const durationInDays = DateTime.fromISO(endtime)
              .diff(DateTime.fromISO(starttime), 'days')
              .as('days');
            return (
              durationInDays >= 3 &&
              DateTime.fromISO(starttime) >= fullStartDate &&
              DateTime.fromISO(endtime) <= fullEndDate
            );
          });
        })
        .catch(() => []);
    }
  });

  const handleCurrenDisplayedDateChange = (range: [string, string]) => {
    setCurrentDisplayedDates([DateTime.fromISO(range[0]), DateTime.fromISO(range[1])]);
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }} id={'date-range-section'}>
      <DateRangeDropdown
        startDate={currentSelectedDates[0]}
        endDate={currentSelectedDates[1]}
        selectedDateName={selectedDateName || ''}
        onSave={(period) => dispatch(setMainPagePeriod(period))}
        inputVariant="noBorder"
        maxWidth={310}
        fullWidth={true}
        allowTimeZoneChange
      />
      <Box sx={{ display: 'flex', mt: '8px' }}>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('left')}
            sx={{ padding: 0, marginRight: 1, rotate: '180deg', height: 56 }}
            disabled={currentDisplayedDates[0] <= fullStartDate}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
        <Box
          sx={{
            width: '100%',
            height: '72px',
            position: 'relative',
            touchAction: 'none',
            userSelect: 'none'
          }}
          id={'date-range-slider'}>
          <WellsButton
            wells={wellsLookup.data}
            startDate={currentDisplayedDates[0].toISODate() as string}
            endDate={currentDisplayedDates[1].toISODate() as string}
            selectedRange={currentSelectedDates}
            onWellClick={onSave}
          />
          <BarChart
            selectedRig={selectedRig}
            minStartDate={
              fullStartDate
                .setZone(DateTime.local().zoneName, { keepLocalTime: true })
                .startOf('day')
                .toISO() as string
            }
            maxEndDate={
              fullEndDate
                .setZone(DateTime.local().zoneName, { keepLocalTime: true })
                .endOf('day')
                .toISO() as string
            }
            startDate={currentDisplayedDates[0].toISODate() as string}
            endDate={currentDisplayedDates[1].toISODate() as string}
            setCurrentDisplayedDates={handleCurrenDisplayedDateChange}
            selectedRange={currentSelectedDates}
          />
          <TimeRangeSlider
            startDate={currentDisplayedDates[0].toISODate() as string}
            endDate={currentDisplayedDates[1].toISODate() as string}
            selectedRange={currentSelectedDates}
            onRangeChange={setCurrentSelectedDates}
          />
        </Box>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('right')}
            sx={{ padding: 0, marginLeft: 1, height: 56 }}
            disabled={currentDisplayedDates[1] >= fullEndDate}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};
