import React, { useCallback, useMemo } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { VIEW_BY_TYPE } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getPercentDiff, getValue } from '@helpers';
import {
  getBaselineComparisonSlice,
  setEquipmentFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import EquipmentComparisonKpis from '@components/Sections/BaselineComparisonSection/SubSections/EquipmentComparison/EquipmentComparisonKpis';
import { BaselineComparisonChart } from '@components/Charts';
import DataTypeSelect from '../../../../Inputs/DataTypeSelect';
import LookupSelect from '@components/Inputs/LookupSelect';
import { LOOKUP_EQUIPMENT } from '../../../../../lookups';
import { useBaselineComp } from '@hooks/useBaselineComp';
import HourDaySelect from '../../../../Inputs/HourDaySelect';
import { BaselineChartTypeSelect } from '@components/Inputs';
import { useBaselineQueries, useDisplayUnit } from '@hooks';

function EquipmentComparison() {
  const displayUnit = useSelector(getDisplayUnit);
  const dispatch = useDispatch();
  const { getEquipmentSeries } = useBaselineComp();
  const { baselineEquipmentQueries } = useBaselineQueries();
  const {
    equipment: { dataType, chartType, equipmentType, viewBy }
  } = useSelector(getBaselineComparisonSlice);
  const { valueWithUnit } = useDisplayUnit();

  const handleChange = useCallback((val) => {
    dispatch(setEquipmentFilters(val));
  }, []);
  const mainData = baselineEquipmentQueries[0].data;
  const baselineData = baselineEquipmentQueries[1].data;

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    unit,
    max = 0
  } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    return getEquipmentSeries(mainData, baselineData);
  }, [displayUnit, mainData, baselineData, dataType, equipmentType, viewBy]);

  const loading = baselineEquipmentQueries?.some((item) => item.isFetching);

  const getSumData = (unit) => {
    let key = `${unit}${displayUnit}`;
    if (mainData[key]) {
      let selectedRigData = Object.values(mainData[key]);
      let baseData = Object.values(baselineData[key]);
      selectedRigData = selectedRigData.reduce((a, b) => a + getValue(b), 0);
      baseData = baseData.reduce((a, b) => a + getValue(b), 0);
      return {
        ...valueWithUnit(selectedRigData - baseData, displayUnit, true),
        percent: getPercentDiff(selectedRigData, baseData),
        mainRaw: selectedRigData,
        baseRaw: baseData
      };
    }
    return {};
  };

  const { sortedEquipmentLookup, total, topFourKpis } = useMemo(() => {
    if (!baselineData || !mainData) return {};

    const safeAddItem = (unit, header) => {
      let key = `${unit}${displayUnit}`;
      if (baselineData[key] && mainData[key]) {
        return {
          ...getSumData(unit),
          header
        };
      }
      return null;
    };

    let res = {
      hpu: safeAddItem('HPU', 'HPU'),
      td: safeAddItem('TD', 'Topdrive'),
      dw: safeAddItem('DW', 'Drawworks'),
      mp: safeAddItem('MP', 'MudPumps'),
      waterTreatment: safeAddItem('WaterTreatment', 'Water treatment'),
      lighting: safeAddItem('Lighting', 'Lighting'),
      air: safeAddItem('Air', 'Air'),
      hvac: safeAddItem('HVAC', 'HVAC'),
      waterCooling: safeAddItem('WaterCooling', 'Water cooling'),
      gallery: safeAddItem('Galley', 'Galley'),
      cementing: safeAddItem('Cementing', 'Cementing'),
      pipeHandling: safeAddItem('PipeHandling', 'Pipe handling')
    };

    // Filter out null entries
    res = Object.fromEntries(Object.entries(res).filter(([_, value]) => value !== null));

    const topKpis = Object.entries(res)
      .map(([key, item]) => ({ ...item, percent: parseFloat(item.percent), key }))
      .sort((a, b) => Math.abs(b.percent) - Math.abs(a.percent));

    const sortedEquipmentLookup = [...topKpis].map((kpi) => {
      const item = LOOKUP_EQUIPMENT.find((lookup) => kpi.header === lookup.name);
      return {
        value: item?.value?.toString(),
        ...item
      };
    });
    let totalMain = Object.values(res).reduce((a, b) => a + getValue(b.mainRaw), 0);
    let totalBase = Object.values(res).reduce((a, b) => a + getValue(b.baseRaw), 0);
    const total = {
      ...valueWithUnit(totalMain - totalBase, displayUnit, true),
      percent: getPercentDiff(totalMain, totalBase)
    };
    return { sortedEquipmentLookup, topFourKpis: topKpis.slice(0, 4), total };
  }, [displayUnit, mainData, baselineData]);

  const equipmentTypeValue = useMemo(() => {
    if (!sortedEquipmentLookup?.length) return [];
    return equipmentType.filter((i) => sortedEquipmentLookup.find((si) => si.value === i.value));
  }, [equipmentType, sortedEquipmentLookup]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Equipment comparison</Typography>
        </Stack>
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparing to the Baseline
        </Typography>
        <EquipmentComparisonKpis loading={loading} topKpis={topFourKpis} total={total} />
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparison over time
        </Typography>
        <BaselineComparisonChart
          dataType={dataType}
          chartType={chartType}
          viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
          chartActions={[
            <LookupSelect
              loading={!sortedEquipmentLookup?.length}
              options={sortedEquipmentLookup || []}
              label={'Equipment'}
              key={'equipment-select'}
              value={equipmentTypeValue}
              onChange={(val) => {
                handleChange({ equipmentType: val });
              }}
            />,
            <BaselineChartTypeSelect
              key={'baseline-chart-type-select'}
              value={chartType}
              handleChange={(val) => {
                handleChange({ chartType: val });
              }}
            />,
            <DataTypeSelect
              key={'data-type-select'}
              value={dataType}
              onChange={(val) => {
                handleChange({ dataType: val });
              }}
            />,
            <HourDaySelect
              key={'view-by-select'}
              value={viewBy}
              handleChange={(val) => {
                handleChange({ viewBy: val });
              }}
            />
          ]}
          yAxisTitle={unit}
          mainMax={max}
          loading={loading}
          mainSeries={mainSeries}
          baseSeries={baseSeries}
          categories={categories}
        />
      </Stack>
    </Paper>
  );
}

EquipmentComparison.propTypes = {};

EquipmentComparison.defaultProps = {};

export default EquipmentComparison;
