import React, { useCallback, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@esgian/esgianui';
import { useQuery } from '@tanstack/react-query';
import { fetchOverviewKeyFiguresData } from '../../api/Rig';
import Wave from 'react-wavify';
import moment from 'moment';
import { API_ROLLUP, DATE_FORMAT, DATE_TIMEZONE_FORMAT } from '../../constants';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '../../store/features';
import { BarControllerChartOptions } from 'chart.js';

interface BarChartProps {
  selectedRig: string;
  minStartDate: string;
  maxEndDate: string;
  startDate: string;
  endDate: string;
  selectedRange: [string, string];
  setCurrentDisplayedDates: (v: [string, string]) => void;
}

const BarChart: React.FC<BarChartProps> = ({
  selectedRig,
  minStartDate,
  maxEndDate,
  startDate,
  endDate,
  selectedRange,
  setCurrentDisplayedDates
}) => {
  const displayUnit = useSelector(getDisplayUnit);
  const {
    data: overviewData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['overviewData', { selectedRig }],
    queryFn: ({ queryKey, signal }: any) => {
      return fetchOverviewKeyFiguresData(
        signal,
        { ...queryKey[1], startDate: minStartDate, endDate: maxEndDate },
        API_ROLLUP.DAY
      );
    },
    enabled: !!selectedRig
  });

  const currentOverviewData = useMemo(() => {
    const data = overviewData?.clarifyData?.[`Rig${displayUnit}`];
    if (!data) return [];

    return Object.entries(data).map(([date, value]: any) => {
      return {
        date: date.split('T')[0],
        value: parseFloat(value)
      };
    });
  }, [overviewData, displayUnit]);

  const labels = useMemo(
    () => currentOverviewData.map((entry) => entry.date),
    [currentOverviewData]
  );

  const values = useMemo(
    () => currentOverviewData.map((entry) => entry.value),
    [currentOverviewData]
  );

  const backgroundColors = useMemo(() => {
    const [rangeStart, rangeEnd] = selectedRange.map((date) => moment(date, DATE_FORMAT));

    return currentOverviewData.map((entry) => {
      const entryDate = moment(entry.date, DATE_FORMAT);
      return entryDate.isBetween(rangeStart, rangeEnd, 'day', '[]') ? '#0E9EFF' : '#7B7B7B';
    });
  }, [currentOverviewData, selectedRange]);

  const chartData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: values,
          backgroundColor: backgroundColors,
          borderColor: 'transparent',
          borderWidth: 0
        }
      ]
    };
  }, [labels, values, backgroundColors]);

  const debounce = (func: any, delay: number) => {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSetCurrentDisplayedDates = useCallback(debounce(setCurrentDisplayedDates, 20), []);

  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
        zoom: {
          zoom: {
            drag: {
              enabled: false
            },
            pinch: {
              enabled: true
            },
            wheel: {
              enabled: true,
              modifierKey: 'ctrl'
            },
            mode: 'x',
            onZoom: ({ chart }: any) => {
              const xAxis = chart.scales.x;
              debouncedSetCurrentDisplayedDates([
                moment(minStartDate).add(xAxis.min, 'day').format(DATE_TIMEZONE_FORMAT),
                moment(minStartDate).add(xAxis.max, 'day').format(DATE_TIMEZONE_FORMAT)
              ]);
            }
          },
          pan: {
            enabled: true,
            speed: 1,
            mode: 'x',
            onPan: ({ chart }: any) => {
              const xAxis = chart.scales.x;
              debouncedSetCurrentDisplayedDates([
                moment(minStartDate).add(xAxis.min, 'day').format(DATE_TIMEZONE_FORMAT),
                moment(minStartDate).add(xAxis.max, 'day').format(DATE_TIMEZONE_FORMAT)
              ]);
            }
          },
          limits: {
            x: {
              minRange: 180
            }
          }
        }
      },
      scales: {
        x: {
          min: moment(startDate).diff(moment(minStartDate), 'days'),
          max: moment(endDate).diff(moment(minStartDate), 'days'),
          offset: false,
          grid: { display: false },
          ticks: { display: false }
        },
        y: {
          display: false
        }
      },
      layout: {
        padding: 0
      },
      datasets: {
        bar: {
          barThickness: 'flex'
          // maxBarThickness: 6
        }
      }
    };
  }, [minStartDate, startDate, endDate]);

  if (isLoading) {
    return (
      <Wave
        fill="#7B7B7B"
        paused={false}
        style={{ height: '18px' }}
        options={{
          height: 5,
          amplitude: 5,
          speed: 0.4,
          points: 5
        }}
      />
    );
  }

  if (error) {
    return <p>Error fetching data.</p>;
  }

  return (
    <Box sx={{ width: '100%', position: 'absolute', top: 20 }}>
      {currentOverviewData.length > 0 ? (
        <Bar height={20} data={chartData} options={chartOptions as BarControllerChartOptions} />
      ) : (
        <p>No data available for the selected range.</p>
      )}
    </Box>
  );
};

export default BarChart;
