import React, { useMemo, useRef } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { useTheme } from '../../../hooks';
import { CanvasGroupedTimeSeriesBarChart } from '..';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '../../../store/features/filters/MainPageSlice/MainPageSlice';

type EventType = 'TooManyHPU' | 'DW_AUX_Idle' | 'MP_Idle' | 'TooManyEng';

type ChartDataItem = {
  startDate: string;
  endDate: string;
  eventName: string;
  backgroundColor: string;
};

type Event = {
  startDate: string;
  endDate: string;
  type: EventType;
  costSaving: string;
  fuelSaving: string;
};

type FocusAreaTimelineChartProps = {
  loading?: boolean;
  data?: Event[];
};

const LOOKUP: Record<EventType, string> = {
  TooManyEng: 'Too many engines running',
  MP_Idle: 'Idle Mud pumps',
  TooManyHPU: 'Too many HPU pumps online',
  DW_AUX_Idle: 'Idle Drawworks'
};

const FocusAreaTimelineChart: React.FC<FocusAreaTimelineChartProps> = ({ loading, data }) => {
  const { startDate: selectedStartDate, endDate: selectedEndDate } = useSelector(getMainPageSlice);
  const { theme } = useTheme();
  const {
    palette: {
      primary: { main }
    }
  } = theme;
  const ref = useRef<HTMLCanvasElement>(null);
  const chartData = useMemo<ChartDataItem[]>(() => {
    if (!loading && data?.length) {
      return data.map((event) => ({
        startDate: moment.parseZone(event.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment.parseZone(event.endDate).format('YYYY-MM-DDTHH:mm:ss'),
        eventName: LOOKUP[event.type],
        backgroundColor: main,
        saving: `${event?.fuelSaving || '-'} mt`,
        potentialSaving: `${event?.costSaving || '-'} USD`
      }));
    }
    return [];
  }, [data, loading, main]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <Typography variant="h6">Focus area timeline</Typography>
        <Typography variant="body2">Active focus areas</Typography>
      </Stack>
      <CanvasGroupedTimeSeriesBarChart
        chartRef={ref}
        loading={loading}
        stackedBar={true}
        data={chartData || []}
        min={selectedStartDate}
        max={selectedEndDate}
        displayedDaysCount={14}
      />
    </Paper>
  );
};

export default FocusAreaTimelineChart;
