import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Chartjs from '@components/Charts/Chartjs';
import 'chartjs-adapter-moment';
import PropTypes from 'prop-types';
import { useTheme } from '../../../hooks';
import { Box, Stack, ChevronRightIcon, IconButton } from '@esgian/esgianui';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { defaultBarTooltip } from '../ChartJsTooltipHelpers';

ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  TimeScale,
  LinearScale,
  Filler,
  Tooltip,
  zoomPlugin
);

const getOptions = (
  theme,
  sortedData,
  handlePointClick,
  stackedBar,
  syncRefs,
  labels,
  currentMin,
  currentMax,
  min,
  max,
  displayedDaysCount,
  setXAxisRange
) => {
  const {
    palette: {
      opacity: { ten: opacityTen },
      text: { primary },
      neutral: { neutral04 }
    }
  } = theme;
  return {
    commonUpdate: true,
    onClick: function (event) {
      if (!handlePointClick) return;
      const { chart } = event;

      const activePoint = chart.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: false },
        true
      );
      if (activePoint.length) {
        handlePointClick(activePoint[0]);
      }
    },
    onHover: function (event) {
      if (!handlePointClick) return;
      const chartCanvas = event.native.target;
      chartCanvas.style.cursor = 'pointer';
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        min: currentMin,
        max: currentMax,
        type: 'time',
        ticks: {
          callback: function (index) {
            let currentDate = moment.utc(this.getLabelForValue(index));
            if (index >= labels.length) {
              currentDate.format('MMM DD');
            }
            let nextDate = moment.utc(this.getLabelForValue(index + 1));
            let rollup = 'hour';
            if (nextDate.diff(currentDate, 'hours') < 1) {
              rollup = 'min';
            }

            if (currentDate.clone().hour() === 0 && rollup === 'hour') {
              return currentDate.format('MMM DD');
            }
            if (currentDate.clone().startOf('day').isSame(currentDate) && rollup === 'min') {
              return currentDate.format('MMM DD');
            }
            return currentDate.format('HH:mm');
          },
          autoSkip: true,
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        grid: {
          tickLength: 0,
          color: `${neutral04}7F`
        },
        time: {
          displayFormats: {
            millisecond: 'YYYY-MM-DD HH:mm:ss',
            second: 'YYYY-MM-DD HH:mm',
            minute: 'YYYY-MM-DD HH:mm',
            hour: 'YYYY-MM-DD HH',
            day: 'YYYY-MM-DD'
          },
          unit: 'day',
          stepSize: 1
        }
      },
      y: {
        stacked: stackedBar,
        border: {
          display: false
        },

        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        position: 'left',
        beginAtZero: true
      }
    },
    elements: {
      line: {
        tension: 0
      },
      point: {
        radius: 0
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        interaction: {
          mode: 'nearest',
          intersect: true
        },
        external: (context) => defaultBarTooltip(context, sortedData, theme)
      },
      barHoverBackground: {
        syncRefs: syncRefs,
        backgroundColor: opacityTen
      },
      syncTooltipHiderPlugin: {
        syncRefs: syncRefs
      },
      hoverLinePlugin: {
        syncRefs: syncRefs,
        lineColor: primary
      },
      zoom: {
        limits: {
          x: {
            min,
            max,
            maxRange: displayedDaysCount * 24 * 60 * 60 * 1000
          }
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: false
            },
            pinch: {
              enabled: false
            }
          }
        },
        pan: {
          enabled: true,
          mode: 'x',
          onPan: ({ chart }) => {
            const xScale = chart.scales.x;
            setXAxisRange({
              min: xScale.min,
              max: xScale.max
            });
          }
        }
      }
    }
  };
};

function CanvasGroupedTimeSeriesBarChart({
  data: rawData,
  handlePointClick,
  stackedBar,
  syncRefs,
  height,
  loading,
  id,
  chartRef,
  min,
  max,
  displayedDaysCount
}) {
  const [daysCount, setDaysCount] = useState(displayedDaysCount);
  const [xAxisRange, setXAxisRange] = useState();
  const arrow = moment(max).diff(moment(min), 'd') > displayedDaysCount;

  useEffect(() => {
    const dayDiff = moment(max).diff(moment(min), 'day');
    if (dayDiff > displayedDaysCount) {
      setXAxisRange({
        min: moment(min).startOf('d').valueOf(),
        max: moment(min).endOf('d').add(displayedDaysCount, 'd').valueOf()
      });
    } else {
      setDaysCount(dayDiff);
      setXAxisRange({
        min: moment(min).startOf('d').valueOf(),
        max: moment(min).add(dayDiff, 'd').endOf('d').valueOf()
      });
    }
  }, [min, max]);

  const { theme } = useTheme();
  const labels = [...new Set(rawData?.map((event) => event.eventName))];

  const sortedData = useMemo(
    () =>
      rawData
        ?.map((event) => ({
          ...event,
          startDate: moment(event.startDate),
          endDate: moment(event.endDate)
        }))
        .sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf()),
    [rawData]
  );

  const visibleData = useMemo(() => {
    if (xAxisRange?.min && xAxisRange?.max && sortedData.length) {
      return sortedData
        .map((event) => {
          const start = event.startDate.clone().valueOf();
          const end = event.endDate.clone().valueOf();
          if (start >= xAxisRange?.min && end <= xAxisRange?.max) {
            return event;
          } else if (start < xAxisRange?.min && end > xAxisRange?.min && end <= xAxisRange?.max) {
            return { ...event, startDate: moment(xAxisRange?.min) };
          } else if (end > xAxisRange?.max && start >= xAxisRange?.min && start < xAxisRange?.max) {
            return { ...event, endDate: moment(xAxisRange?.max) };
          } else if (start < xAxisRange?.min && end > xAxisRange?.max) {
            return {
              ...event,
              startDate: moment(xAxisRange?.min),
              endDate: moment(xAxisRange?.max)
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
    }
    return [];
  }, [xAxisRange, sortedData]);

  const datasets = useMemo(() => {
    const labelGrouping = {};
    return visibleData?.map((event, i) => {
      let start = event.startDate.valueOf();
      let end = event.endDate.valueOf();

      let stack = undefined;
      let firstStackEntry = false;

      if (!labelGrouping[event.eventName]) {
        stack = { Stack: 'Stack0', LastDate: event.endDate };
        labelGrouping[event.eventName] = [stack];
        firstStackEntry = true;
      } else {
        labelGrouping[event.eventName].forEach((item) => {
          if (!stack && item.LastDate.valueOf() <= event.startDate.valueOf()) {
            stack = { ...item };
            item.LastDate = event.endDate;
          }
        });
        if (!stack) {
          const stackIndex = labelGrouping[event.eventName].length;
          stack = { Stack: `Stack${stackIndex}`, LastDate: event.endDate };
          labelGrouping[event.eventName].push(stack);
          firstStackEntry = true;
        }
      }

      const data = labels.map(() => null);

      if (!firstStackEntry) {
        start -= stack.LastDate.valueOf();
        end -= stack.LastDate.valueOf();
      }
      data[labels.indexOf(event.eventName)] = [start, end];

      return {
        spanGaps: true,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        tension: 0,
        data,
        skipNull: true,
        backgroundColor: event.backgroundColor,
        stack: `${event.eventName}_${stack.Stack}`,
        datalabels: {
          display: false
        },
        barThickness: 21
      };
    });
  }, [visibleData]);

  const chartData = {
    labels,
    datasets
  };

  const options = useMemo(() => {
    if (visibleData?.length && labels?.length) {
      return getOptions(
        theme,
        visibleData,
        handlePointClick,
        stackedBar,
        syncRefs,
        labels,
        xAxisRange.min,
        xAxisRange.max,
        moment(min).startOf('d').valueOf(),
        moment(max).endOf('d').valueOf(),
        daysCount,
        setXAxisRange
      );
    }

    return {};
  }, [visibleData, labels, theme, xAxisRange, daysCount]);

  const handleArrowClick = (side) => {
    const milSecondsCount = daysCount * 24 * 60 * 60 * 1000;
    if (side === 'left') {
      if (xAxisRange.min - milSecondsCount > moment(min).startOf('d').valueOf()) {
        setXAxisRange({
          min: xAxisRange.min - milSecondsCount,
          max: xAxisRange.max - milSecondsCount
        });
      } else {
        setXAxisRange({
          min: moment(min).startOf('d').valueOf(),
          max: moment(min).startOf('d').valueOf() + milSecondsCount
        });
      }
    } else {
      if (xAxisRange.max + milSecondsCount < moment(max).endOf('d').valueOf()) {
        setXAxisRange({
          min: xAxisRange.min + daysCount * 24 * 60 * 60 * 1000,
          max: xAxisRange.max + daysCount * 24 * 60 * 60 * 1000
        });
      } else {
        setXAxisRange({
          min: moment(max).startOf('d').valueOf() - milSecondsCount,
          max: moment(max).endOf('d').valueOf()
        });
      }
    }
  };

  return (
    <Stack sx={{ width: 'auto' }} spacing={1}>
      <Box sx={{ height: height, width: '100%', position: 'relative' }}>
        {!loading && xAxisRange?.min && arrow ? (
          <IconButton
            size={'small'}
            onClick={() => handleArrowClick('left')}
            sx={{
              padding: 0,
              rotate: '180deg',
              position: 'absolute',
              bottom: 5,
              left: 78
            }}
            disabled={xAxisRange.min <= moment(min).startOf('d').valueOf()}>
            <ChevronRightIcon fontSize="large" color={'inherit'} />
          </IconButton>
        ) : null}
        <Chartjs
          loading={loading}
          chartRef={chartRef}
          id={id}
          type={'bar'}
          data={chartData}
          options={options}
        />
        {!loading && xAxisRange?.max && arrow ? (
          <IconButton
            size={'small'}
            onClick={() => handleArrowClick('right')}
            sx={{
              padding: 0,
              position: 'absolute',
              bottom: 5,
              right: -20
            }}
            disabled={xAxisRange.max >= moment(max).endOf('d').valueOf()}>
            <ChevronRightIcon fontSize="large" color={'inherit'} />
          </IconButton>
        ) : null}
      </Box>
    </Stack>
  );
}

CanvasGroupedTimeSeriesBarChart.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  height: PropTypes.string,
  data: PropTypes.array,
  handlePointClick: PropTypes.func,
  stackedBar: PropTypes.bool,
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  syncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ),
  customTooltipHandler: PropTypes.func,
  unit: PropTypes.string,
  includeTooltipSum: PropTypes.bool,
  hideEmptyTooltipValues: PropTypes.bool,
  hideCurrentTooltip: PropTypes.bool,
  handleLogClick: PropTypes.func,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  displayedDaysCount: PropTypes.number.isRequired
};

CanvasGroupedTimeSeriesBarChart.defaultProps = {
  id: 'timeGroupSeries',
  loading: false,
  height: '200px',
  handlePointClick: undefined,
  stackedBar: false,
  syncRefs: [],
  chartRef: undefined,
  handleLogClick: undefined,
  hideCurrentTooltip: false,
  includeTooltipSum: false,
  customTooltipHandler: undefined
};

export default CanvasGroupedTimeSeriesBarChart;
